<template>
  <ul class="top-sidebar" :class="className">
    <li v-for="(item, index) in tabData" :key="index">
      <router-link
        :to="item.link"
        :class="[
          $route.name === item.link.name ? 'top-sidebar--active' : '',
          { 'top-sidebar--disabled': item.disabled },
        ]"
      >
        {{ item.text }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "TopSidebar",
  data() {
    return {};
  },
  props: {
    disabled: Boolean,
    tabData: {
      type: Array,
    },
  },
  computed: {
    className() {
      return {
        "top-sidebar--disabled": this.disabled,
      };
    },
  },
};
</script>

<style lang="scss">
ul.top-sidebar {
  font-size: 2.2rem;
  line-height: 2.7rem;
  font-weight: 500;
  display: flex;
  gap: 14px;
  white-space: nowrap;
  overflow-x: auto;
  padding-bottom: 22px;
  border-bottom: 1px solid var(--background--btn--profile--hover);
  margin-bottom: 40px;
  @include scrollbar();
  @include adaptive(tablet-big) {
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-bottom: 20px;
  }
  @include adaptive(phone) {
    font-size: 1.4rem;
    line-height: 1.7rem;
    padding-bottom: 15px;
  }
  &--disabled {
    color: var(--input--border);
    pointer-events: none;
  }
  & li a {
    color: var(--text--color);
  }
  & a.top-sidebar--active {
    border-bottom: 2px solid #e31a5a;
  }
  a.top-sidebar--disabled {
    color: var(--input--border);
    pointer-events: none;
  }
}
</style>
