export default [
  {
    text: "Профиль",
    link: { name: "ExcellentStudentProfile" },
    key: "profile",
    disabled: false,
  },
  {
    text: "Персональные данные",
    link: { name: "ExcellentStudentPersonalData" },
    key: "personal",
    disabled: false,
  },
  {
    text: "Успеваемость",
    link: { name: "ExcellentStudentPerformance" },
    key: "performance",
    disabled: false,
  },
];
